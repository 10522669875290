import React from 'react';
import { MdTrendingFlat } from 'react-icons/md';
import { useStaticQuery, graphql } from 'gatsby';
import { List, ListItem, ListLink } from './recent-post-list.style';

const RecentPostList = ({ listStyle }) => {
  const blogData = useStaticQuery(graphql`
    query RecentBlogQueryData {
      allContentfulBlogPost(
        filter: { availableOnMaisMilitar: { eq: true } }
        sort: { fields: publishDate, order: DESC }
        limit: 5
      ) {
        edges {
          node {
            title
            slug
          }
        }
      }
    }
  `);

  const latestBlogs = blogData.allContentfulBlogPost.edges || [];

  return (
    <List>
      {latestBlogs &&
        latestBlogs.map((latestBlog, i) => (
          <ListItem key={`${latestBlog.node.slug}-${i}`}>
            <ListLink path={`/blog/${latestBlog.node.slug}`}>
              <MdTrendingFlat className="icon icon-1" />
              <MdTrendingFlat className="icon icon-2" />
              <span>{latestBlog.node.title}</span>
            </ListLink>
          </ListItem>
        ))}
    </List>
  );
};

export default RecentPostList;
