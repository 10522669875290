import React from 'react';
import PropTypes from 'prop-types';
import Heading from '../../../components/ui/Heading';
import RecentPostList from '../recent-post-list';
import PopularTags from '../popular-tags';
import { SidebarWrap, RecentPostListWrap, RecentWidgetBox, TagWidgetBox } from './sidebar.style';

const Sidebar = ({ widgetTitleStyle }) => {
  return (
    <SidebarWrap>
      <RecentWidgetBox>
        <Heading {...widgetTitleStyle}>Posts recentes</Heading>
        <RecentPostListWrap>
          <RecentPostList />
        </RecentPostListWrap>
      </RecentWidgetBox>
      <TagWidgetBox>
        <Heading {...widgetTitleStyle}>Categorias populares</Heading>
        <PopularTags />
      </TagWidgetBox>
    </SidebarWrap>
  );
};

Sidebar.propTypes = {
  widgetTitleStyle: PropTypes.object,
};

Sidebar.defaultProps = {
  widgetTitleStyle: {
    fontSize: '34px',
    fontweight: 500,
    mb: '27px',
  },
};

export default Sidebar;
